<template>
  <div class="home">
    <div class="logo-web">
      <img src="../assets/ll.png" alt="" width="100%" height="100%" />
    </div>
    <v-card width="90%" max-width="400" class="py-5 " rounded="xxl" color="#C39BD3"  >      <!-- สีฟอร์ม -->
      <h2 class="mb-2 text-center" >เข้าสู่ระบบ</h2>
      <v-form class="form-custom" ref="form" @submit.prevent="handleSubmit">
        <!--  -->
        <p>หมายเลขโทรศัพท์</p>
        <v-text-field
          solo
          flat
          outlined
          dense
          ref="form.phoneNumber"
          v-model="phoneNumber"
          required
          counter
          :rules="[
            () => !!phoneNumber || 'โปรดเบอร์โทรศัทพ์',
            () =>
              (!!phoneNumber && phoneNumber.length === 10) ||
              'เบอร์มือถือไม่ถูกต้อง',
          ]"
          maxlength="10"
          label="หมายเลขโทรศัพท์"
          prepend-inner-icon="mdi-phone"
        ></v-text-field>
        <!--  -->
        <p>รหัสผ่าน</p>
        <v-text-field
          solo
          flat
          outlined
          dense
          type="password"
          v-model="password"
          :rules="[() => !!password || 'โปรดกรอกรหัสผ่าน']"
          required
          label="กรอกรหัสผ่าน"
        ></v-text-field>
        <!--  -->
            <v-btn
              x-large
              type="submit"
              color=#A569BD 
              dark width="100%"
              class="mb-4"
              ><v-icon left> mdi-gamepad-variant </v-icon>
          
               เข้าสู่ระบบ
          </v-btn>
          
            <v-btn 
              medium 
              color="null"
              dark
              width="100%"
              @click="handleRegister"
              ><v-icon left>mdi-account-plus</v-icon>
              
              สมัครสมาชิก | รับฟรี 50 ฿
          </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    handleRegister() {
      this.$router.push('/register'); // เปลี่ยนเส้นทางไปยังหน้า Register.vue
    },
    
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$store.commit('setPhoneNumber', this.phoneNumber);
        this.$router.push('/User'); // เปลี่ยนเส้นทางไปยังหน้า user.vue หลังจากที่ฟอร์มถูกยืนยัน
      }
    },
  },
};



</script>

<style lang="scss" scoped>
.logo-web {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
  transform: scale(2); /* เพิ่มขนาดโลโก้ ... เท่า */
  img {
    object-fit: cover;
  }
}
.home {
  height: 100%;
  width: 100vw;

  // สีพื้นหลัง (ไล่สี)
  /* background: radial-gradient(at 100% 100%, #5f5f5f, rgba(0, 0, 0, 0) 40vw), radial-gradient(at 0% 0%, #be643a, rgba(0, 0, 0, 0) 40vw) ; */
  background-image: url(../assets/bg.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  //background-image: url("~@/assets/ll.png");

  background-size: cover, contain;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  p {
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }
  h2 {
    font-weight: 400;
    letter-spacing: 1.2px;
    color: #fff ;  // สีหัวข้อความ เข้าสู่ระบบ
  }
  p {
    font-size: 15px;
    b {
      color: rgb(255, 0, 0);
    }
  }
}
.form-custom {
  padding: 0 15px;
}
.bg-card{
  background-image: url("~@/assets/ll.png");
  background-size: cover, contain;
}

</style>